import {CampaignAnalyticsSummary} from "../model/CampaignAnalyticsSummary";
import {
    Analytics,
    ReceiverGroupAnalytics,
    TemplateAnalytics,
    TimespanAnalytics,
    TotalCampaignAnalytics
} from "../model/Analytics";
import {Campaign} from "../model/Campaign";

const calcStartDate = () => {
    let date = new Date();
    date.setMonth(date.getMonth() - 13)
    date.setDate(date.getDate() - 2)
    return date;
}

const calcEndDate = () => {
    let date = new Date();
    date.setMonth(date.getMonth() - 1)
    date.setDate(date.getDate() - 2)
    return date;
}

const calcBaselineEndDate = () => {
    let date = new Date();
    date.setMonth(date.getMonth() - 13)
    date.setDate(date.getDate() - 2 + 7 * 6)
    return date;
}

const calcTimespanStartDate = (i: number) => {
    let date = new Date();
    date.setMonth(date.getMonth() - 13 + i)
    date.setDate(date.getDate() - 2)
    return date;
}
//166 Receiver
export const campaignOverview: CampaignAnalyticsSummary[] = [
    { //100% Progress
        campaignId: "demoCampaign",
        name: "Demo Kampagne",
        startDate: calcStartDate(),
        endDate: calcEndDate(),
        status: "Finished",
        total: 1992,
        sent: 1992,
        clicked: 373
    }
]

//@ts-ignore
export const demoCampaign: Campaign = {
    campaignId: "demoCampaign",
    name: "Demo Kampagne",
    startDate: calcStartDate(),
    endDate: calcEndDate(),
    baselineEnabled: true,
    baselineEndDate: calcBaselineEndDate(),
    status: "Finished"
}


//Total: Click 18%, 34% Report, 36% Interaction
const campaignAnalytics: TotalCampaignAnalytics = {//24% Click, 28% Report, 24% Interaction
    baselineAnalytics: {//33% Click, 14% Report, 62% Interaction
        total: 498,
        sent: 498,
        reported: 69,
        clicked: 164,
        interactableTotal: 166,
        interactableClicked: 63,
        interacted: 4,
        interactedCritical: 35,
    },
    followUpAnalytics: {//14% Click, 41% Report, 22% Interaction
        total: 1494,
        sent: 1494,
        reported: 612,
        clicked: 209,
        interactableTotal: 498,
        interactableClicked: 104,
        interacted: 3,
        interactedCritical: 19,
    }
}

const receiverGroupAnalytics: ReceiverGroupAnalytics[] = [
    {//14% Click, 37% Report
        receiverGroupId: "Marketing",
        name: "Marketing",
        reported: 220,
        sent: 597,
        clicked: 83,
        interactableClicked: 0,
        interacted: 0,
        interactedCritical: 0,
    },
    {//9% Click, 46% Report
        receiverGroupId: "IT",
        name: "IT",
        reported: 183,
        sent: 398,
        clicked: 35,
        interactableClicked: 0,
        interacted: 0,
        interactedCritical: 0,
    },
    {//19% Click, 30% Report
        receiverGroupId: "HR",
        name: "HR",
        reported: 44,
        sent: 149,
        clicked: 28,
        interactableClicked: 0,
        interacted: 0,
        interactedCritical: 0,
    },
    {//26% Click, 27% Report
        receiverGroupId: "Sales",
        name: "Sales",
        reported: 234,
        sent: 848,
        clicked: 227,
        interactableClicked: 0,
        interacted: 0,
        interactedCritical: 0,
    }
]

const templateAnalytics: TemplateAnalytics[] = [
    {
        templateId: "demoTemplate",
        name: "Ihre Bestellung wurde versandt – Bestätigungsdetails",
        reported: 56,
        sent: 166,
        clicked: 63,
        interactable: true,
        interacted: 4,
        interactedCritical: 35
    },
    {
        templateId: "demoTemplate",
        name: "Dringende Aktualisierung Ihrer Unternehmensinformationen",
        reported: 56,
        sent: 166,
        clicked: 52,
        interactable: false,
        interacted: 0,
        interactedCritical: 0
    },
    {
        templateId: "demoTemplate",
        name: "Einladung zum virtuellen Meeting mit unserem Team",
        reported: 56,
        sent: 166,
        clicked: 49,
        interactable: false,
        interacted: 0,
        interactedCritical: 0
    },
    {
        templateId: "demoTemplate",
        name: "Neue Rechnung verfügbar",
        reported: 56,
        sent: 166,
        clicked: 28,
        interactable: false,
        interacted: 0,
        interactedCritical: 0
    },
    {
        templateId: "demoTemplate",
        name: "Vertragsdokumente zur Überprüfung",
        reported: 56,
        sent: 166,
        clicked: 23,
        interactable: false,
        interacted: 0,
        interactedCritical: 0
    },
    {
        templateId: "demoTemplate",
        name: "Neue Richtlinien für Lieferanten und Partner",
        reported: 56,
        sent: 166,
        clicked: 20,
        interactable: false,
        interacted: 0,
        interactedCritical: 0
    },
    {
        templateId: "demoTemplate",
        name: "Rechnung Nr. V9406-914 Online Auktion",
        reported: 56,
        sent: 166,
        clicked: 16,
        interactable: false,
        interacted: 0,
        interactedCritical: 0
    },
    {
        templateId: "demoTemplate",
        name: "Wichtige Information zu Ihrer Transaktion",
        reported: 56,
        sent: 166,
        clicked: 17,
        interactable: false,
        interacted: 0,
        interactedCritical: 0
    },
    {
        templateId: "demoTemplate",
        name: "Ihr Gewinn wartet auf Sie",
        reported: 56,
        sent: 166,
        clicked: 15,
        interactable: false,
        interacted: 0,
        interactedCritical: 0
    },
    {
        templateId: "demoTemplate",
        name: "Datenschutzanfrage - Sofortige Aktion erforderlich",
        reported: 56,
        sent: 166,
        clicked: 43,
        interactable: true,
        interacted: 2,
        interactedCritical: 10
    },
    {
        templateId: "demoTemplate",
        name: "HR-Mitteilung: Wichtige Änderungen",
        reported: 56,
        sent: 166,
        clicked: 32,
        interactable: true,
        interacted: 1,
        interactedCritical: 6
    },
    {
        templateId: "demoTemplate",
        name: "Sicherheitswarnung: Bitte überprüfen Sie Ihr Konto",
        reported: 56,
        sent: 166,
        clicked: 29,
        interactable: true,
        interacted: 0,
        interactedCritical: 3
    }
]

//Total: Click 18%, 34% Report, 36% Interaction
//135 mails avg per timespan
const timespanAnalytics: TimespanAnalytics[] = [
    {
        timespanIndex: 1,
        startDate: calcTimespanStartDate(0),
        endDate: calcTimespanStartDate(1),
        sent: 350,
        reported: 49,
        clicked: 115
    },
    {
        timespanIndex: 2,
        startDate: calcTimespanStartDate(1),
        endDate: calcTimespanStartDate(2),
        sent: 218,
        reported: 42,
        clicked: 74
    },
    //14% Click, 41% Report, 22% Interactio
    {//29% Click, 18% Report
        timespanIndex: 3,
        startDate: calcTimespanStartDate(2),
        endDate: calcTimespanStartDate(3),
        sent: 142,
        reported: 40,
        clicked: 36
    },
    {//24% Click, 26% Report
        timespanIndex: 4,
        startDate: calcTimespanStartDate(3),
        endDate: calcTimespanStartDate(4),
        sent: 121,
        reported: 41,
        clicked: 24
    },
    {//17% Click, 24% Report
        timespanIndex: 5,
        startDate: calcTimespanStartDate(4),
        endDate: calcTimespanStartDate(5),
        sent: 157,
        reported: 52,
        clicked: 21
    },
    {//19% Click, 31% Report
        timespanIndex: 6,
        startDate: calcTimespanStartDate(5),
        endDate: calcTimespanStartDate(6),
        sent: 144,
        reported: 54,
        clicked: 22
    },
    {//20% Click, 29% Report
        timespanIndex: 7,
        startDate: calcTimespanStartDate(6),
        endDate: calcTimespanStartDate(7),
        sent: 127,
        reported: 46,
        clicked: 20
    },
    {//15% Click, 36% Report
        timespanIndex: 8,
        startDate: calcTimespanStartDate(7),
        endDate: calcTimespanStartDate(8),
        sent: 125,
        reported: 60,
        clicked: 13
    },
    {//12% Click, 42% Report
        timespanIndex: 9,
        startDate: calcTimespanStartDate(8),
        endDate: calcTimespanStartDate(9),
        sent: 131,
        reported: 70,
        clicked: 10
    },
    {//13% Click, 41% Report
        timespanIndex: 10,
        startDate: calcTimespanStartDate(9),
        endDate: calcTimespanStartDate(10),
        sent: 148,
        reported: 53,
        clicked: 14
    },
    {//11% Click, 58% Report
        timespanIndex: 11,
        startDate: calcTimespanStartDate(10),
        endDate: calcTimespanStartDate(11),
        sent: 154,
        reported: 79,
        clicked: 13
    },
    {//12% Click, 49% Report
        timespanIndex: 12,
        startDate: calcTimespanStartDate(11),
        endDate: calcTimespanStartDate(12),
        sent: 175,
        reported: 95,
        clicked: 11
    }
]

export const demoAnalytics: Analytics = {
    campaignAnalytics: campaignAnalytics,
    receiverGroupAnalytics: receiverGroupAnalytics,
    templateAnalytics: templateAnalytics,
    timespanAnalytics: timespanAnalytics,
    reportingEnabled: true
}

export const demoAnalyticsWithTemplates = (demoTemplates: { name: string, templateId: string }[]) => {
    const templateAnalyticsFilledIn = templateAnalytics.map((t, i) => {
        if (demoTemplates[i])
            return Object.assign(t, demoTemplates[i])
        else
            return t
    })

    return {
        campaignAnalytics: campaignAnalytics,
        receiverGroupAnalytics: receiverGroupAnalytics,
        templateAnalytics: templateAnalyticsFilledIn,
        timespanAnalytics: timespanAnalytics,
        reportingEnabled: true
    }
}