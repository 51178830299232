import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/css/froala_editor.pkgd.min.css';
import 'froala-editor/js/plugins.pkgd.min.js';
import React, {useEffect, useRef, useState} from 'react';
import FroalaEditorView from 'react-froala-wysiwyg/FroalaEditorView';
import classes from '../../../styling/preview/css/main.module.css';
import {useTranslation} from 'react-i18next';
import '../../../styling/preview/css/froala_styles.css';
import logo2xSingle from '../../../styling/preview/img/2x_single.png';
import envelopeOpenIcon from '../../../styling/preview/img/envelope-open-icon.png';
import look2xLogo from '../../../styling/preview/img/look2x-logo.png';
import newMailIcon from '../../../styling/preview/img/new-mail-icon.png';
import Lottie, {LottieRefCurrentProps} from "lottie-react";
import openMailAnimation from '../../../styling/preview/Lottie/Open_Mail_animation.json';
import successAnimation from '../../../styling/preview/Lottie/Success_Animation.json';
import ReactJoyride, {ACTIONS, CallBackProps, EVENTS, STATUS} from 'react-joyride';
import {LearningPageInfo} from "../../../model/LearningPageInfo";


interface Props {
  learningPageInfo: LearningPageInfo
  customerLogo: any,
}

const EmailPreview: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const {learningPageInfo, customerLogo} = props

  useEffect(() => {
    // Disable all <a> tags on page load
    const allLinks = document.querySelectorAll('div#mail a');
    allLinks.forEach(link => {
      link.addEventListener('click', e => {
        e.preventDefault();
      });
    });
  }, [learningPageInfo.filledBody]);

  useEffect(() => {
    const updatedSteps = learningPageInfo.learningContents.map((content, index) => {
      // Create a step object for each learning content
      return {
        target: '#' + content.location,
        title: content.title,
        content: content.content as string | React.ReactElement,
        //Set disableBeacon = true for first step
        ...(index === 0 && { disableBeacon: true }),
      };
    });
    // Update the steps state with the new array
    setJoyrideState((prevState: any) => ({
      ...prevState,
      steps: updatedSteps.concat([{
        target: '#mail',
        title: 'Geschafft!',
        content: <div><Lottie animationData={successAnimation} loop={true} style={{height: '4rem'}}
                              lottieRef={lottieRef} onClick={openMail} alt="E-Mail öffnen"/>
          Herzlichen Glückwunsch! Du hast alle Hinweise zur Erkennung von Phishing-Mails gelesen.
        </div>,
        disableBeacon: true,
      }]),
    }));
  }, [learningPageInfo.learningContents]);

  const [emailIconSrc, setEmailIconSrc] = useState<string>(newMailIcon);
  const toggleTour = () => {
    setEmailIconSrc(envelopeOpenIcon);
    //Set disableBeacon of first step to true
    /*
        setJoyrideState((prevState:any) => ({
          ...prevState,
          stepIndex: prevState.stepIndex +1,
          steps: prevState.steps.map((step:any, index: number) => {
            if (index == prevState.stepIndex+1) {
              step = {
                ...step,
                disableBeacon: true,
                
              }
            }
            console.log(step);
            return step;
          })
        }));
        */
    //Set run to true
    setJoyrideState((prevState: any) => ({
      ...prevState,
      run: true,
      // If button is clicked during tour, go to first step again
      steps: prevState.steps.map((step: any, index: number) => {
        if (prevState.stepIndex != 0) {
          if (index == 0) {
            step = {
              ...step,
              disableBeacon: false,
            }
          }
        }
        return step;
      }),
      stepIndex: 0,
    }));
  };

  const [joyrideState, setJoyrideState] = useState<any>({
    run: false,
    steps: [],
    stepIndex: 0, // a controlled tour
  });

  const handleJoyrideCallback = (data: CallBackProps) => {
    const { action, index, status, type } = data;
    if (([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND] as string[]).includes(type)) {
      const nextStepIndex = index + (action === ACTIONS.PREV ? -1 : 1);
      setJoyrideState((prevState: any) => ({
        ...prevState,
        stepIndex: nextStepIndex,
      }));
    } else if (([STATUS.FINISHED, STATUS.SKIPPED] as string[]).includes(status)) {
      // Need to set our running state to false, so we can restart if we click start again.
      setJoyrideState((prevState: any) => ({
        ...prevState,
        stepIndex: 0,
        run: false,
      }));
    }
  };
  const [brandingEnabled, setBrandingEnabled] = useState<boolean>(learningPageInfo.brandingEnabled);
  const [primaryColor, setPrimaryColor] = useState<string>(brandingEnabled ? learningPageInfo.primaryColor != '' ? learningPageInfo.primaryColor : '#a0aed9' : '#a0aed9');
  const [secondaryColor, setSecondaryColor] = useState<string>(brandingEnabled ? learningPageInfo.secondaryColor != '' ? learningPageInfo.secondaryColor : '#000' : '#000');
  const [introContentStyle, setIntroContentStyle] = useState<any>({});
  const [startTourButtonStyle, setStartTourButtonStyle] = useState<any>({});
  const [didYouKnowSectionContentItemStyle, setDidYouKnowSectionContentItemStyle] = useState<any>({});
  const [homepageButtonStyle, setHomepageButtonStyle] = useState<any>({});
  const [textColor, setTextColor] = useState<string>('#ffffff');
  const [hoverColor, setHoverColor] = useState<string>('');
  const [hoverHomepageButton, setHoverHomepageButton] = useState<boolean>(false);
  const [hoverStartTourButton, setHoverStartTourButton] = useState<boolean>(false);
  useEffect(() => {
    if (brandingEnabled) {
      setIntroContentStyle({ backgroundColor: primaryColor, color: textColor });
      setStartTourButtonStyle({ backgroundColor: hoverStartTourButton ? hoverColor : primaryColor, color: textColor });
      setDidYouKnowSectionContentItemStyle({ backgroundColor: primaryColor, color: textColor });
      setHomepageButtonStyle({ backgroundColor: hoverHomepageButton ? hoverColor : primaryColor, color: textColor });
      setJoyrideStyles({
        options: {
          arrowColor: '#fff',
          backgroundColor: '#fff',
          primaryColor: primaryColor,
          textColor: '#000000',
          zIndex: 1000,
        },
        buttonNext: {
          color: textColor,
        },
        buttonBack: {
          borderRadius: '4px',
          marginRight: '0.5rem',
          backgroundColor: primaryColor,
          color: textColor,
        }
      })
    }
  }, [primaryColor, textColor, hoverColor, hoverStartTourButton, hoverHomepageButton]);

  const darkenHexColor = (hex: string, amount: number = 20) => {
    let r: number = parseInt(hex.slice(1, 3), 16);
    let g: number = parseInt(hex.slice(3, 5), 16);
    let b: number = parseInt(hex.slice(5, 7), 16);

    r = Math.max(0, r - amount);
    g = Math.max(0, g - amount);
    b = Math.max(0, b - amount);

    return "#" + [r, g, b].map(x => x.toString(16).padStart(2, '0')).join('');
  }
  const darkenRgbColor = (rgb: string, amount: number = 20) => {
    const [r, g, b] = rgb.match(/\d+/g)?.map(Number) ?? [0, 0, 0];

    return `rgb(${Math.max(0, r - amount)}, ${Math.max(0, g - amount)}, ${Math.max(0, b - amount)})`;
  }

  const getRelativeLuminance = (color: string): number => {
    // Check if the color is in hexadecimal format
    if (color.startsWith('#')) {
      // Convert hexadecimal to RGB
      const r = parseInt(color.slice(1, 3), 16);
      const g = parseInt(color.slice(3, 5), 16);
      const b = parseInt(color.slice(5, 7), 16);

      return calculateRelativeLuminanceFromRGB(r, g, b);
    }

    // Check if the color is in RGB format
    const rgbMatch = color.match(/^rgb\((\d+),\s*(\d+),\s*(\d+)\)$/);
    if (rgbMatch) {
      const r = parseInt(rgbMatch[1], 10);
      const g = parseInt(rgbMatch[2], 10);
      const b = parseInt(rgbMatch[3], 10);

      return calculateRelativeLuminanceFromRGB(r, g, b);
    }

    // Invalid color format
    throw new Error('Invalid color format');
  }
  const calculateRelativeLuminanceFromRGB = (r: number, g: number, b: number): number => {
    const luminance = 0.2126 * Math.pow(r / 255, 2.2) + 0.7152 * Math.pow(g / 255, 2.2) + 0.0722 * Math.pow(b / 255, 2.2);
    return luminance;
  }

  useEffect(() => {
    if (primaryColor != null && primaryColor != '') {
      const isHex: boolean = /^#/.test(primaryColor);
      const darkenAmount: number = 40;

      // Generate hover color
      setHoverColor(isHex
        ? darkenHexColor(primaryColor, darkenAmount)
        : darkenRgbColor(primaryColor, darkenAmount));

      //Set text color based on primary color
      const luminance = getRelativeLuminance(primaryColor);
      const textColor = luminance > 0.5 ? '#000000' : '#ffffff';
      setTextColor(textColor);
    }
  }, [primaryColor]);

  useEffect(() => {
    setIntroContentStyle({ backgroundColor: primaryColor, color: textColor });
    setStartTourButtonStyle({ backgroundColor: hoverStartTourButton ? hoverColor : primaryColor, color: textColor, cursor: hoverStartTourButton ? 'pointer' : 'default'});
    setDidYouKnowSectionContentItemStyle({ backgroundColor: primaryColor, color: textColor });
    setHomepageButtonStyle({ backgroundColor: hoverHomepageButton ? hoverColor : primaryColor, color: textColor, cursor: hoverHomepageButton ? 'pointer' : 'default' });
    setJoyrideStyles({
      options: {
        arrowColor: '#fff',
        backgroundColor: '#fff',
        primaryColor: primaryColor,
        textColor: '#000000',
        zIndex: 1000,
      },
      buttonNext: {
        color: textColor,
      },
      buttonBack: {
        borderRadius: '4px',
        marginRight: '0.5rem',
        backgroundColor: primaryColor,
        color: textColor,
      }
    })
  }, [primaryColor, textColor, hoverColor, hoverStartTourButton, hoverHomepageButton]);

  const [joyrideStyles, setJoyrideStyles] = useState({
    options: {
      arrowColor: '#fff',
      backgroundColor: '#fff',
      primaryColor: primaryColor,
      textColor: '#000000',
      zIndex: 1000,
    },
    buttonNext: {
      color: textColor,
    },
    buttonBack: {
      borderRadius: '4px',
      marginRight: '0.5rem',
      backgroundColor: primaryColor,
      color: '#000000',
    }
  })

  //Open Mail Animation
  const guideSectionRef = useRef<HTMLDivElement>(null);
  const openMail = () => {
    lottieRef.current!.goToAndStop(75, true);
    setTimeout(() => {
      guideSectionRef.current!.scrollIntoView({behavior: 'smooth'});
    }, 300);
    // Start the opacity animation
    setTimeout(() => {
      guideSectionRef.current!.style.opacity = "1";
    }, 500); // Delay to ensure scroll completes before animation starts
    setTimeout(() => {
      toggleTour();
    }, 1000);
  }

  const [joyRideScrollOffset, setJoyRideScrollOffset] = useState<number>(350);

  const lottieRef = useRef<LottieRefCurrentProps | null>(null);

  return (
    <>
      <div className={classes.container}>
        <ReactJoyride steps={joyrideState.steps} continuous={true} showProgress={true}
                      scrollOffset={joyRideScrollOffset} run={joyrideState.run} stepIndex={joyrideState.stepIndex}
                      callback={handleJoyrideCallback} styles={joyrideStyles} locale={{
          back: 'Zurück',
          close: 'Schließen',
          last: 'Beenden',
          next: 'Weiter',
          open: 'Öffnen',
          skip: 'Überspringen'
        }}/>
        <header className={classes.header}>
          <div className={classes.headerLogo}>
            <img className={classes.ownLogo} src={look2xLogo} alt="look2x" />
          </div>
          <div className={classes.headerLogoCustomer}>
            {props.customerLogo != null && brandingEnabled ? (
              props.customerLogo.type === 'image/svg+xml' ? (
                // Render SVG directly
                  <img className={classes.customerLogo} src={URL.createObjectURL(props.customerLogo)}/>
              ) : (
                // Render PNG or JPG
                <img className={classes.customerLogo} src={URL.createObjectURL(props.customerLogo)} alt="Logo" />
              )
            ) : (
              <p></p>
            )}
          </div>
        </header >
        <main>
          <section className={classes.introSection}>
            <div className={classes.introHeadline}>
              Schau beim nächsten mal besser <img className={classes.logo2xOnly} src={logo2xSingle} alt="2x" /> hin!
            </div>
            <div className={classes.introContent} style={introContentStyle}>
              <div className={classes.introContentText}>
                Sieht so aus, als hättest du auf einen Link in einer simulierten Phishing-Mail geklickt. Aber keine Sorge!
              </div>
              <div className={classes.introContentEnumeration}>
                <div className={classes.introContentEnumerationItem}><img className={classes.checkmarkIcon}
                                                                          src={"/assets/img/check-mark.svg"}
                                                                          height="20"/> Deine Daten sind sicher
                </div>
                <div className={classes.introContentEnumerationItem}><img className={classes.checkmarkIcon}
                                                                          src={"/assets/img/check-mark.svg"}
                                                                          height="20"/> Dein Klick hat keine negativen
                  Konsequenzen
                </div>
                <div className={classes.introContentEnumerationItem}><img className={classes.checkmarkIcon}
                                                                          src={"/assets/img/check-mark.svg"}
                                                                          height="20"/> Du hast die Chance zu lernen,
                  wie du dich in Zukunft schützen kannst
                </div>
              </div>
              <div className={classes.introContentOpenNow}>
                Öffne die E-Mail um mehr zu erfahren!
              </div>
            </div>
            <div className={classes.introContentMailButton}>
              <Lottie animationData={openMailAnimation} loop={true} style={{height: '6rem'}} lottieRef={lottieRef}
                      initialSegment={[0, 40]} onClick={openMail} alt="E-Mail öffnen"/>
            </div>
          </section>
          <section className={classes.guideSection} ref={guideSectionRef}>
            <div className={classes.guideMail} id="mail">
              <div className={classes.mailHeaderBar}>
                <div className={classes.mailHeaderBarIcon}>
                  <img src={"/assets/img/minimize-window.svg"} width="20" height="20"/>
                </div>
                <div className={classes.mailHeaderBarIcon}>
                  <img src={"/assets/img/maximize-window.svg"} width="20" height="20"/>
                </div>
                <div className={classes.mailHeaderBarIcon}>
                  <img src={"/assets/img/close-window.svg"} width="20" height="20"/>
                </div>
              </div>
              <div className={classes.mailHeaderInfo}>
                <div className={classes.mailHeaderItem}>
                  <strong>An:</strong> [Deine E-Mail-Adresse]
                </div>
                <div className={classes.mailHeaderItem}>
                  <span id="l_sender"><strong>Von:</strong> {learningPageInfo.filledSenderAddress}</span>
                </div>
                <div className={classes.mailHeaderItem}>
                  <span id="l_subject"><strong>Betreff:</strong> {learningPageInfo.filledSubject}</span>
                </div>
              </div>
              <div className={classes.mailBody}>
                <FroalaEditorView
                    model={learningPageInfo.filledBody}
                />
              </div>
            </div>
            <div className={classes.guideButton}>
              <button className={classes.startTourButton} style={startTourButtonStyle} onClick={toggleTour} onMouseEnter={() => setHoverStartTourButton(true)} onMouseLeave={() => setHoverStartTourButton(false)}>{joyrideState.run != true ? 'Guide und Tipps anzeigen' : 'Guide neu starten'}</button>
            </div>
          </section>
          <section className={classes.additionalInfoSection}>
            <section className={classes.didYouKnowSection}>
              <div className={classes.didYouKnowSectionHeadline}>
                Wusstest du schon, dass ...
              </div>
              <div className={classes.didYouKnowSectionContent}>
                <div className={classes.didYouKnowSectionContentItem} style={didYouKnowSectionContentItemStyle}>
                  ... Phishing das Ziel hat, sensible Daten wie Passwörter oder Zahlungsinformationen zu stehlen?
                </div>
                <div className={classes.didYouKnowSectionContentItem} style={didYouKnowSectionContentItemStyle}>
                  ... Absenderadressen von E-Mails gefälscht werden können?
                </div>
                <div className={classes.didYouKnowSectionContentItem} style={didYouKnowSectionContentItemStyle}>
                  ... Phishing das Haupteinfallstor für Cyberangriffe ist?
                </div>
              </div>
            </section>
            <section className={classes.outroSection}>
              <div className={classes.outroSectionLogo}>
                <img className={classes.ownLogo} src={look2xLogo} alt="look2x" />
              </div>
              <div className={classes.outroSectionContent}>
                look2x hat es sich zum Ziel gemacht, Betrügern das Handwerk zu legen, indem wir
                Menschen helfen einen sicheren Umgang mit E-Mails zu pflegen. Jede*r hat das Potential Cyber-Hero zu werden!
              </div>
              <div className={classes.outroSectionButton}>
                <a href="#"><button className={classes.homepageButton} style={homepageButtonStyle} onMouseEnter={() => setHoverHomepageButton(true)} onMouseLeave={() => setHoverHomepageButton(false)}>Zur Homepage</button></a>
              </div>
            </section>
          </section>
        </main>
        <footer className={classes.footer}>
          <div className={classes.footerCopyright}>
            &copy; 2024 look2x GmbH
          </div>
        </footer>
      </div>
    </>
  );
}

export default EmailPreview;